<template>
  <div class="modal-window__wrapper">
    <div class="modal-window">
      <div class="modal-window__body">
        <slot />
      </div>
    </div>
    <div class="modal-window__background" @click="onClose"></div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
const $props = defineProps({
  title: {
    type: String,
    required: false,
  },
  customClass: {
    type: String,
    required: false,
  },
  bgDisabled: {
    type: Boolean,
    required: false,
  },
})

useHead({
  bodyAttrs: {
    class: 'overflow-hidden',
  },
})

const $emit = defineEmits(['closeModalWindow'])

const onClose = () => {
  if (!$props.bgDisabled) {
    $emit('closeModalWindow')
  }
}

const escapeHandler = (e) => {
  if (e.key === 'Escape') {
    onClose()
  }
}

onMounted(() => {
  document.addEventListener('keydown', escapeHandler)
})

onUnmounted(() => {
  document.removeEventListener('keydown', escapeHandler)
})
</script>

<style lang="scss" scoped>
.modal-window__title {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
  color: white;
}

.modal-window {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  z-index: 1000;
  background: transparent;
  width: fit-content;
  margin: auto;

  @media (max-width: 900px) {
    padding: 16px;
  }

  &__fullModal {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: 1000;
    background: white;
    width: fit-content;
    margin: auto;
  }
}

.modal {
  img {
    width: 12px;
    height: 12px;
  }
}

.modal-window__wrapper {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
}

.modal-window__title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-window__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161c2a;
  opacity: 0.84;
  z-index: 999;
}
</style>
